const dogPoundUrls = [
    "dogpound.local.com",
    "dogpound.customer.stage.presence.fit",
    "customer.thedogpound.com"
]

function isDodPoundUrl() {
    const hostName = window.location.hostname;
    return dogPoundUrls.includes(hostName);
}

const FITNESS_SECRET_KEY = "sk_ft_PoW3r37g9OUWA7S39KKE";
const DOG_POUND_SECRET_KEY = "sk_dp_PoW3r37g9OUWA7S39MMJ";

const DOG_POUND_PLATFORM_NAME = "DOGPOUND";
const PRESENCE_PLATFORM_NAME = "Presence";

const DOG_POUND_FAVICON = "/favicon-dp.ico";
const PRESENCE_FAVICON = "/favicon.ico";

export const getLogoImageImage = () => {
    
    const logoPath = isDodPoundUrl() ? "dp_logo" : "logo";
    const SOURCES_DP = [
        {
            srcSet: "/imgs/" + logoPath + "/webp/logo.webp",
            type: "image/webp"
        },
        {
            srcSet: "/imgs/" + logoPath + "/png/logo.png",
            type: "image/png"
        }
    ];
    return SOURCES_DP;
};

export function getSecretKey() {
    return isDodPoundUrl() ? DOG_POUND_SECRET_KEY : FITNESS_SECRET_KEY;
}

export function getPlatformName() {
    return isDodPoundUrl() ? DOG_POUND_PLATFORM_NAME : PRESENCE_PLATFORM_NAME;
}

export function getPlatformFavIcon() {
    return isDodPoundUrl() ? DOG_POUND_FAVICON : PRESENCE_FAVICON;
}
